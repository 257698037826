@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

/* //navbar css */
.navbar {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 800 !important;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0 8px 6px -6px gray;
  --webkit-box-shadow: 0 8px 6px -6px gray;
  border-bottom: solid gray !important;
}

.nav-link {
  font-weight: 600 !important;
}

.navbar-nav{
  
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.active {
  border-bottom: 2px solid rgb(240, 143, 108);
}

.navbar-brand {
  font-weight: 700;
  font-family: "roboto", sans-serif;
  letter-spacing: 3px;
}

.search-form {
  margin-right: 10px;
  margin-top: 4px;
}
.search-form input {
  border: none;
  border-radius: 0;
}
.search-form button {
  background-color: #000000;
  border-radius: 0;
  color: white;
}

/* =============================
==========footer============= */

.footer {
  color: rgb(10, 10, 10);
  padding: 25px;
  background: #e0a3f8; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ca8be7,
    #d39df2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f3a4a4, #d869eb);
  /* position: absolute;
  bottom: 0;
  
   */

   width: 100%;

}

.footer a {
  text-decoration: none;
  color: rgb(14, 13, 13);
  padding: 10px;
}

.footer a:hover {
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
}
/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}
/* ========================================= */
/* =========contact us ========= */
.contactus {
  margin: 0;
  padding: 0;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}

.cat-btn {
  padding: 40px 0px;
  font-size: 24px;
  text-transform: uppercase;
}
.cat-btn:hover {
  background-color: #434343;
  color: white;
}
/* ================
====dashboard
=================== */

.dashboard {
  margin-top: 100px !important;
}

.dashboard-menu h4 {
  background-color: #434343 !important;
  color: white;
  padding: 20px 0px;
}


/* +++++++++++++++++++++++++++++++++++++Register page css++++++++++++++++++++++++++++++++++++++ */

.register{
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  flex-direction: column;


  background-image: url(../public/images/back.jpg);

  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;

}

.form-group{
  margin: 10px;
}

.formRegister{
  background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8.6px);
-webkit-backdrop-filter: blur(8.6px);
border: 1px solid rgba(255, 255, 255, 0.12);  

padding: 40px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}


/* +++++++++++++++++++++++++++++++++++++++++++PRoducts PAge+++++++++++++++++++++++++++++++++++++++ */

.product-link{
  text-decoration: none;
  
}



/* google auth button  */
/* $white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2; */

.google-btn {
  margin-top: 10px;
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff ;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px #4285f4 ;
    cursor: pointer;
  }
  &:active {
    background: #1669F2;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);


/* +++++++++++++++++++++++++++++++++==skeleton++++++++++++++++++++++++++++++++++++++++++++ */

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}