.home .home-slider .slide{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding-top: 9rem;
}
.home .home-slider .slide .content{
    flex: 1 1 40rem;
}
.home .home-slider .slide .image{
    flex: 1 1 40rem;
}
.home .home-slider .slide .image img{
    width: 100%;
}
.home .home-slider .slide .content span{
    color: var(--green);
    font-size: 2.5rem;
}
.home .home-slider .slide .content h3{
    color: var(--black);
    font-size: 5rem;
}
.home .home-slider .slide .content p{
    color: var(--light-color);
    font-size: 2rem;
    padding: .5rem 0;
    line-height: 2rem;
}
.swiper-pagination-bullet-active{
    background: var(--green);
}




.home-slider{
    margin-top: 60px;
  
    width: 100%;
    height: 500px;
    overflow: hidden;
   
}
.slider-sec{
    /* display: block; */
}
.slider-sec-img{
    /* width: 100%;
    height: 30%;
     */
     width: 100%; 
     height: auto; 
     display: block;

}

.home-container{
   
    display: flex;
   
    justify-content: flex-start;
    padding: 0;
    margin: 0;
  
    /* padding-left: 5px; */
   
  
}

.filter-section{
    box-shadow: 0px 0px 10px -2px rgb(76, 75, 75);
    /* background-color: aqua; */
    padding: 3%;
    border-radius: 10px;
}



.cat-list-checkbox{
    font-size: 0.9rem;

    font-weight: 500;
    color: rgb(61, 60, 60);
}
.btn-filter{
    /* background-color: aqua; */
    font-weight: 600;
    color: black;
    border-color: rgb(80, 151, 231);

    background-color: white;
    width: 80%;
    padding: 5px;
    border-radius: 10px;
}

.btn-filter:hover{
    color:white;
    background-color:rgb(110, 189, 246); 
}


  /* home pro list */

.home-pro-list{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -2px rgb(130, 129, 129);

}  
.pro-card{
   
    display: flex;
    align-items: center;
    justify-content: center;
   
    /* background-color: rgb(247, 246, 246); */
}

.card-box{

    position: relative;
    overflow: hidden;
    text-align: center;
    box-shadow: 0px 0px 10px -2px rgb(130, 129, 129);
}

.card-box .faHeart,
.card-box .faEye{
    position: absolute;
    top: 1.5rem;
    background: #eee;
    border-radius: 50%;
    height: 1.5rem;
    padding: 0.5rem;
    width: 1.5rem;
    line-height: 5rem;
    cursor: pointer;
    font-size: 2rem;
    color: var(--black);
}

.card-box .faHeart:hover,
.card-box .faEye:hover{
    background-color: rgb(110, 189, 246);
    color: #fff;
}
.card-box .faHeart{
    right: -15rem;
}
.card-box .faEye{
    left: -15rem;
}
.card-box:hover .faHeart{
    right: 0.8rem;
}
.card-box:hover .faEye{
    left: 0.8rem;
}



.card-btn{
  
  display: flex;
  justify-content: center;
  gap: 10px;
}
.card-btn-add,.card-btn-details{
    display: inline-block;
   
    padding: .3rem 0.5rem;
    border: .1rem solid rgb(110, 189, 246);
    color:#160b0b;
    cursor: pointer;
    font-size: 1rem;
    border-radius: .5rem;
    position: relative;
    overflow: hidden;
    z-index: 0;
    gap: 1px;
    background-color: white;
    font-weight: 600;
    margin-top: 1rem;
}

 .card-btn-add::before,.card-btn-details::before{
    content: "";
    position: absolute;
    top: 0;right: 0;
    width: 0%;
    height: 100%;
    background-color: rgb(110, 189, 246);
    transition: .3s linear;
    z-index: -1;
}
 
.card-btn-add:hover:before,.card-btn-details:hover:before{
    width: 100%;
    left: 0;
}
.card-btn-add:hover,.card-btn-details:hover{
    color: #fff;
} 

.pro-price {
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
   
}
.pro-price .fp {
   
    font-size: 15px;
    font-weight: bold;
}

.pro-price .sp {
    /* border: 2px solid green; */
    text-decoration: line-through;
    color: #474646;
    font-size: 12px;
}

.pro-price .off{
    font-size: 12px;
    color:  rgb(110, 189, 246);
}